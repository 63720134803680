import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../components/LandingPage.module.css';
import GalleryAndUploadAction from '../components/GalleryAndUploadAction';
import UploadInstructionsDialog from '../components/UploadInstructionsDialog';
import { useDispatch, useSelector } from 'react-redux';
import { setDialogVisibility, setDeeplinkState, resetFilters} from 'redux/slices/';
import {  fetchFiltered } from 'redux/thunks/songListThunks';
import { AppDispatch, RootState } from 'src/redux/store';
import SongList from '../../MusicLibrary/components/SongList';
import LibraryButton from '../components/LibraryButton';
import CircularProgress from '@mui/material/CircularProgress'; //

interface HeaderProps {
  title: string;
  subtitle: string;
}

const LandingPageContainer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const songs = useSelector((state: RootState) => state.songList.songs);
  const loading = useSelector((state: RootState) => state.songList.loading);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const limitedSongs = songs.slice(0, 10);

  const handleUploadStart = async () => {
    dispatch(setDialogVisibility({ dialogName: 'uploadDialog', visibility: true }));
    dispatch(setDeeplinkState(true));
    navigate('/search/library');
  }

  const handleNavigateToLibrary = () => {
    navigate('/search/library');
  }


  //todo need to fetch favorite songs  
  useEffect(() => {
    dispatch(resetFilters());
    dispatch(fetchFiltered());
  }, []);

  return (
        <>
          <div className={styles.sectionContainer}>
            <Header title='Match Music to Your Reference' subtitle='A U D I OS * V I D E O S * B R I E F S * S P O T I F Y' />
            <div>
              <UploadInstructionsDialog open={open} onClose={handleClose} />
              <GalleryAndUploadAction onUploadStart={handleUploadStart} />
              <div onClick={handleOpen} style={{ marginTop: '20px', textAlign: 'center', color: 'white', cursor: 'pointer' }}>
                <small style={{ color: 'var(--main-colors-light-grey)' }}>Need Help?</small>
              </div>
            </div>
          </div>


          {loading ? (
          <div className={styles.loadingSpinner}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          limitedSongs.length > 0 && (
            <div className={styles.sectionContainer}>
            <Header title='BROWSE FOR MUSIC' subtitle='Featured Tracks' />
            <div className={styles.songListWrapper}>
              <SongList songs={limitedSongs} autoScrollList={false}  />
            </div>
            <LibraryButton handleClick={handleNavigateToLibrary} />
          </div>
          )
        )}

          {/* Add more sections below */}
          <div className={styles.sectionContainer}>
            <Header title='' subtitle='' />
          </div>

        </>
  );
};

const Header: React.FC<HeaderProps> = ({ title, subtitle }) => (
  <>
    <div className={styles.headerTitle}>
      {title}
    </div>
    <div className={styles.headerSubtitle}>
      {subtitle}
      {/* Upload your audio reference or {subtitle} <a style={{ color: 'white', textDecoration: 'none' }} href='/search/library'>browse</a> for music. */}
    </div>
  </>

);


export default LandingPageContainer;